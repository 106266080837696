<template>
  <div>
    <vue-element-loading
      :active="appLoading"
      :is-full-screen="true"
      background-color="#FFFFFF"
      color="#182444"
      spinner="bar-fade-scale"
    />
    <ServerError v-if="ServerError" />
    <v-snackbar v-model="showSnackBar" color="#ff6200" right top timeout="2000">
      <v-layout wrap justify-center>
        <v-flex text-left class="align-self-center">
          <span style="color: white">
            {{ msg }}
          </span>
        </v-flex>

        <v-flex text-right>
          <v-btn small :ripple="false" text @click="showSnackBar = false">
            <v-icon style="color: white">mdi-close</v-icon>
          </v-btn>
        </v-flex>
      </v-layout>
    </v-snackbar>
    <v-layout wrap justify-start pt-2>
      <v-flex xs12 sm3 align-self-center pl-8>
        <span
          class="title1"
          :style="{
            'font-size':
              $vuetify.breakpoint.name == 'xs'
                ? '20px'
                : $vuetify.breakpoint.name == 'sm'
                ? '20px'
                : $vuetify.breakpoint.name == 'md'
                ? '25px'
                : $vuetify.breakpoint.name == 'lg'
                ? '25px'
                : '30px',
          }"
          >Add GST</span
        >
      </v-flex>
    </v-layout>
    <v-layout wrap justify-start pa-4>
      <v-flex xs12 sm6>
        <v-layout wrap justify-start px-4>
          <v-flex xs12 sm10 text-left>
            <span class="title2">Create Restaurant Name</span></v-flex
          >
          <v-flex xs12 sm10 text-right>
            <v-text-field
              dense
              v-model="newRestaurant"
              outlined
              type="text"
              hide-details="auto"
            ></v-text-field>
            <span class="title2" style="color: orange" @click="addRestaurants()"
              >Create <v-icon color="orange">mdi-plus</v-icon></span
            >
          </v-flex>
          <v-flex xs12 sm10 pb-4 text-left>
            <v-layout wrap v-if="Restaurants.length > 0">
              <v-flex
                xs12
                pt-4
                v-for="(contact, index) in Restaurants"
                :key="index"
              >
                <v-layout wrap>
                  <v-flex xs11>
                    <v-text-field
                      dense
                      disabled
                      hide-details
                      v-model="Restaurants[index]"
                      outlined
                      type="text"
                      hide-spin-buttons
                    />
                  </v-flex>
                  <v-flex xs1 align-self-center text-center>
                    <v-icon @click="deleteRestaurants(index)">
                      mdi-delete
                    </v-icon>
                  </v-flex>
                </v-layout>
              </v-flex>
            </v-layout>
          </v-flex>
        </v-layout>
        <v-layout wrap justify-start px-4>
          <v-flex xs12 sm6 md4>
            <span class="title2">Is GST present?</span>

            <v-radio-group
              dense
              v-model="gstAvailable"
              row
              class="mt-0 pt-0"
              hide-details="auto"
            >
              <v-radio label="Yes" :value="true"></v-radio>
              <v-radio label="No" :value="false"></v-radio>
            </v-radio-group>
          </v-flex>
          <v-flex xs12 sm10 pt-4 pt-sm-0 text-left v-if="gstAvailable === true">
            <span class="title2">GST Number</span>
            <v-text-field
              dense
              v-model="gstNumber"
              outlined
              type="text"
              hide-details="auto"
            >
            </v-text-field>
          </v-flex>
        </v-layout>
        <v-layout wrap justify-start>
          <v-flex xs12>
            <v-card elevation="0">
              <v-card-title class="headline text-center"
                >GST Rates</v-card-title
              >
              <v-card-text>
                <!-- Flex Layout for Selecting GST Rates -->
                <v-layout row wrap>
                  <v-flex
                    v-for="rate in gstRates"
                    :key="rate.value"
                    xs2
                    class="mx-2"
                  >
                    <v-card
                      width="70px"
                      height="60px"
                      :class="{
                        'selected-card': selectedGSTRate === rate.value,
                        'unselected-card': selectedGSTRate !== rate.value,
                      }"
                      @click="selectGSTRate(rate.value)"
                      class="d-flex justify-center align-center"
                      style="cursor: pointer"
                    >
                      <div
                        class="d-flex justify-center align-center"
                        style="height: 100%"
                      >
                        <span>{{ rate.label }}</span>
                      </div>
                    </v-card>
                  </v-flex>
                </v-layout>
              </v-card-text>
            </v-card>
          </v-flex>
          <v-flex xs12 sm10 px-0 px-sm-4 pt-4 text-left>
            <span class="title2">Fssai Liscence Number</span>
            <v-text-field
              dense
              v-model="fssaiNumber"
              outlined
              type="text"
              hide-details="auto"
            >
            </v-text-field>
          </v-flex>
          <v-flex xs12 sm10 pt-4 text-left px-4>
            <span class="title2">Supply type(State)</span>
            <v-select
              v-model="state"
              :items="states"
              label="Select State"
              dense
              outlined
              hide-details="auto"
              autocomplete
            >
            </v-select>
          </v-flex>
          <v-flex xs12 sm10 px-0 px-sm-4 pt-4 text-left>
            <span class="title2">Country</span>
            <v-text-field
              dense
              v-model="country"
              outlined
              type="text"
              hide-details="auto"
            >
            </v-text-field>
          </v-flex>
        </v-layout>
      </v-flex>
      <v-flex xs12 sm6>
        <v-layout wrap justify-start>
          <v-flex xs12 sm10 md4 pr-0 pr-sm-4 text-left>
            <span class="title2">Total Table</span>
            <v-text-field
              dense
              v-model="totalTable"
              outlined
              type="text"
              hide-details="auto"
            >
            </v-text-field>
          </v-flex>
          <v-flex xs12 sm10 md4 pt-4 pt-sm-0 pr-0 pr-sm-4 text-left>
            <span class="title2">Total Floor</span>
            <!-- <v-text-field
              dense
              v-model="totalFloor"
              outlined 
              type="text"
              hide-details="auto"
            >
            </v-text-field> -->
            <v-select
              dense
              hide-details="auto"
              v-model="totalFloor"
              :items="FloorList"
              :menu-props="{ maxHeight: '400' }"
              outlined
              placeholder="Select Floor"
            />
          </v-flex>
          <v-flex xs12 align-self-start pt-4>
            <span class="title2">Room Service Time</span>
            <v-layout wrap>
              <v-flex xs12 sm2 pb-4 text-left>
                <v-checkbox
                  hide-details
                  class="mt-0"
                  label="24 Hrs"
                  v-model="timezone1"
                ></v-checkbox>
              </v-flex>
              <v-flex
                xs12
                sm5
                text-left
                text-sm-center
                v-if="timezone1 === false"
              >
                <vue-timepicker
                  format="hh:mm A"
                  v-model="roomStartTime"
                  placeholder="Start Time"
                ></vue-timepicker>
              </v-flex>
              <v-flex xs12 sm5 v-if="timezone1 === false">
                <vue-timepicker
                  format="hh:mm A"
                  v-model="roomEndTime"
                  placeholder="End Time"
                ></vue-timepicker>
              </v-flex>
            </v-layout>
          </v-flex>
          <v-flex xs12 align-self-start pt-4>
            <span class="title2">Restaurant Time</span>
            <v-layout wrap>
              <v-flex xs12 sm2 pb-4 text-left>
                <v-checkbox
                  hide-details
                  class="mt-0"
                  label="24 Hrs"
                  v-model="timezone2"
                ></v-checkbox>
              </v-flex>
              <v-flex
                xs12
                sm5
                text-left
                text-sm-center
                v-if="timezone2 === false"
              >
                <vue-timepicker
                  format="hh:mm A"
                  v-model="restautantStartTime"
                  placeholder="Start Time"
                ></vue-timepicker>
              </v-flex>
              <v-flex xs12 sm5 v-if="timezone2 === false">
                <vue-timepicker
                  format="hh:mm A"
                  v-model="restautantEndTime"
                  placeholder="End Time"
                ></vue-timepicker>
              </v-flex>
            </v-layout>
          </v-flex>
        </v-layout>
        <v-layout wrap justify-start>
          <v-flex xs12 sm10 md6 pt-4 text-right px-4 align-self-end>
            <v-btn @click="add()" block class="btnstly" style="cursor: pointer"
              ><span style="font-family: LexendFont; text-transform: none"
                >Save</span
              ></v-btn
            >
          </v-flex>
        </v-layout>
      </v-flex>
    </v-layout>
  </div>
</template>
  <script>
import axios from "axios";
import VueTimepicker from "vue2-timepicker";
import "vue2-timepicker/dist/VueTimepicker.css";
import moment from "moment";
export default {
  components: {
    "vue-timepicker": VueTimepicker,
  },
  props: ["hotelId"],
  data() {
    return {
      showSnackBar: false,
      timeout: 2000,
      ServerError: false,
      appLoading: false,
      msg: null,
      gstAvailable: false,
      gstNumber: "",
      fssaiNumber: "",
      state: "",
      country: "India",
      timezone1: false,
      timezone2: false,
      roomStartTime: "",
      roomEndTime: "",
      restautantStartTime: "",
      restautantEndTime: "",
      selectedGSTRate: null,
      totalFloor: "",
      totalTable: "",
      Restaurants: [],
      newRestaurant: "",
      // Stores the selected GST rate value
      gstRates: [
        { label: "5%", value: 5 },
        { label: "12%", value: 12 },
        { label: "18%", value: 18 },
        { label: "28%", value: 28 },
      ],
      rules: {
        required: (value) => !!value || "Required.",
        counter: (value) => value.length <= 20 || "Max 20 characters",

        email: (value) => {
          const pattern =
            /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
          return !value || pattern.test(value) || "Invalid e-mail.";
        },
      },
      FloorList: [],
      states: [
        "Andhra Pradesh",
        "Arunachal Pradesh",
        "Assam",
        "Bihar",
        "Chhattisgarh",
        "Goa",
        "Gujarat",
        "Haryana",
        "Himachal Pradesh",
        "Jharkhand",
        "Karnataka",
        "Kerala",
        "Madhya Pradesh",
        "Maharashtra",
        "Manipur",
        "Meghalaya",
        "Mizoram",
        "Nagaland",
        "Odisha",
        "Punjab",
        "Rajasthan",
        "Sikkim",
        "Tamil Nadu",
        "Telangana",
        "Tripura",
        "Uttar Pradesh",
        "Uttarakhand",
        "West Bengal",
        "Andaman and Nicobar Islands",
        "Chandigarh",
        "Dadra and Nagar Haveli",
        "Daman and Diu",
        "Lakshadweep",
        "Delhi",
        "Puducherry",
        "Ladakh",
        "Jammu and Kashmir",
      ],
    };
  },
  mounted() {
    this.getFloors();
  },
  methods: {
    selectGSTRate(value) {
      this.selectedGSTRate = value; // Set the selected GST rate
    },
    addRestaurants() {
      // Add the new Category to the array if it's not empty
      if (this.newRestaurant) {
        this.Restaurants.push(this.newRestaurant);
        this.newRestaurant = ""; // Reset the new Category field
      }
    },
    deleteRestaurants(index) {
      this.Restaurants.splice(index, 1); // Remove the Category at the given index
    },
    validation() {
      if (!this.location) {
        this.msg = "Please enter location name";
        this.showSnackBar = true;
        return;
      } else if (!this.mainTownName) {
        this.msg = "Please enter main town name";
        this.showSnackBar = true;
        return;
      } else if (!this.distanceFromTown) {
        this.msg = "Please enter distance from main town";
        this.showSnackBar = true;
        return;
      } else if (!this.link) {
        this.msg = "Please enter location link";
        this.showSnackBar = true;
        return;
      } else if (!this.address) {
        this.msg = "Please enter address";
        this.showSnackBar = true;
        return;
      } else {
        this.add();
      }
    },
    add() {
      if(this.roomStartTime&&this.roomEndTime){
      var roomStartTime = moment(this.roomStartTime, "hh:mm A").format("HH:mm");
      var roomEndTime = moment(this.roomEndTime, "hh:mm A").format("HH:mm");
     
      }else{
        roomEndTime="";
        roomStartTime="";
      }
      if(this.restautantEndTime&&this.restautantStartTime){
        var restautantStartTime = moment(
        this.restautantStartTime,
        "hh:mm A"
      ).format("HH:mm");
      var restautantEndTime = moment(this.restautantEndTime, "hh:mm A").format(
        "HH:mm"
      );}
      else{
        restautantStartTime="";
        restautantEndTime="";
      }
      console.log("id in 2:", this.hotelId);
      this.appLoading = true;
      axios({
        method: "POST",
        url: "/room/add/restaurant/info",
        data: {
          restaurentNames: this.Restaurants,
          gstAvailable: this.gstAvailable,
          gstNumber: this.gstNumber,
          fssaiNumber: this.fssaiNumber,
          state: this.state,
          country: this.country,
          selectedGSTRate: this.selectedGSTRate,
          totalFloor: this.totalFloor,
          totalTable: this.totalTable,
          roomStartTime: roomStartTime,
          roomEndTime: roomEndTime,
          restautantStartTime: restautantStartTime,
          restautantEndTime: restautantEndTime,
          roomId: localStorage.getItem("RoomId"),
        },
        headers: {
          token: localStorage.getItem("token"),
        },
      }).then((response) => {
        this.appLoading = false;
        if (response.data.status == true) {
          this.msg = response.data.msg;
          this.showSnackBar = true;
          // this.$emit("next-tab");
          this.$emit('id-received', { hotelId: this.$route.query.id });  
        } else {
          this.msg = response.data.msg;
          this.showSnackBar = true;
        }
      });
      // }
    },
    getFloors() {
      this.appLoading = true;
      axios
        .get("/hotel/get/floor", {
          headers: {
            token: localStorage.getItem("token"),
          },
          params: {
            hotelId: this.$route.query.id,
          },
        })
        .then((response) => {
          this.appLoading = false;
          this.FloorList = response.data.data;
        })
        .catch((err) => {
          this.appLoading = false;
          this.ServerError = true;
          console.log(err);
        });
    },
  },
};
</script>
<style scoped>
.btnstly {
  background: linear-gradient(
    to bottom,
    rgba(255, 133, 86, 1),
    rgba(247, 75, 9, 1),
    rgba(255, 70, 0, 1)
  );
  color: white;
}

/* Styling for the selected GST card */
.selected-card {
  border: 2px solid #1976d2; /* Highlighting selected card with a border */
  background-color: #e3f2fd; /* Light blue background for selected */
}

/* Styling for the unselected GST card */
.unselected-card {
  border: 1px solid #e0e0e0; /* Regular border for unselected */
  background-color: #f5f5f5; /* Light grey background for unselected */
}
</style>