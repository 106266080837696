<template>
  <div>
    <vue-element-loading
      :active="appLoading"
      :is-full-screen="true"
      background-color="#FFFFFF"
      color="#182444"
      spinner="bar-fade-scale"
    />
    <ServerError v-if="ServerError" />
    <v-snackbar v-model="showSnackBar" color="#ff6200" right top timeout="2000">
      <v-layout wrap justify-center>
        <v-flex text-left class="align-self-center">
          <span style="color: white">
            {{ msg }}
          </span>
        </v-flex>

        <v-flex text-right>
          <v-btn small :ripple="false" text @click="showSnackBar = false">
            <v-icon style="color: white">mdi-close</v-icon>
          </v-btn>
        </v-flex>
      </v-layout>
    </v-snackbar>
    <v-layout wrap justify-start pt-2>
      <v-flex xs12 sm3 align-self-center pl-8>
        <span
          class="title1"
          :style="{
            'font-size':
              $vuetify.breakpoint.name == 'xs'
                ? '20px'
                : $vuetify.breakpoint.name == 'sm'
                ? '20px'
                : $vuetify.breakpoint.name == 'md'
                ? '25px'
                : $vuetify.breakpoint.name == 'lg'
                ? '25px'
                : '30px',
          }"
          >Add Room Rate</span
        >
      </v-flex>
    </v-layout>
    <v-layout wrap justify-center pb-4 px-8>
      <v-flex xs12 sm6>
        <v-layout wrap justify-center>
          <v-flex xs12 sm10 pt-4 text-left>
        <span class="title2">Start Date</span>
        <v-menu
          ref="menu"
          v-model="menu"
          :close-on-content-click="false"
          :return-value.sync="startDate"
          transition="scale-transition"
          offset-y
          min-width="auto"
        >
          <template v-slot:activator="{ on, attrs }">
            <v-text-field
              v-model="startDate"
              outlined
              readonly
              dense
              hide-details="auto"
              clearable
              class="rounded-0"
              v-bind="attrs"
              v-on="on"
            ></v-text-field>
          </template>
          <v-date-picker
            v-model="startDate"
            no-title
            :min="startDate"
            scrollable
            @change="$refs.menu.save(startDate)"
          >
            <v-spacer></v-spacer>
          </v-date-picker>
        </v-menu>
      </v-flex>
          <v-flex xs12 sm10 pt-4 text-left>
        <span class="title2">End Date</span>
        <v-menu
          ref="menu2"
          v-model="menu2"
          :close-on-content-click="false"
          :return-value.sync="endDate"
          transition="scale-transition"
          offset-y
          min-width="auto"
        >
          <template v-slot:activator="{ on, attrs }">
            <v-text-field
              v-model="endDate"
              outlined
              readonly
              dense
              hide-details="auto"
              clearable
              class="rounded-0"
              v-bind="attrs"
              v-on="on"
            ></v-text-field>
          </template>
          <v-date-picker
            v-model="endDate"
            no-title
            :min="endDate"
            scrollable
            @change="$refs.menu2.save(endDate)"
          >
            <v-spacer></v-spacer>
          </v-date-picker>
        </v-menu>
      </v-flex>
          <v-flex xs12 sm10 pt-4 text-left>
            <span class="title2">Base Rates (Single/Double Occupency)</span>
            <v-text-field
              dense
              v-model="baseRate"
              outlined
              type="number"  hide-spin-buttons
              hide-details="auto"
            >
            </v-text-field>
            <span v-if="netRate" class="title3" style="color: red;">Net Rate: ₹ {{ netRate }}</span>
</v-flex>
<v-flex xs12 sm10 pt-4 text-left>
            <span class="title2">Paid Child Rates({{roomData.childAge}})/Extra Matters</span>
            <v-text-field
              dense
              v-model="paidChildWithmatter"
              outlined
              type="number"  hide-spin-buttons
              hide-details="auto"
            >
            </v-text-field>
</v-flex>
<v-flex xs12 sm10 pt-4 text-left>
            <span class="title2">Paid Child Rates({{roomData.childAge}})/Without Matters</span>
            <v-text-field
              dense
              v-model="paidChildWithoutmatter"
              outlined
              type="number"  hide-spin-buttons
              hide-details="auto"
            >
            </v-text-field>
</v-flex>
<v-flex xs12 sm10 pt-4 text-left>
            <span class="title2">Extra Adult Rates /Extra Matters</span>
            <v-text-field
              dense
              v-model="extraAdultWithmatter"
              outlined
              type="number"  hide-spin-buttons
              hide-details="auto"
            >
            </v-text-field>
</v-flex>
<v-flex xs12 sm10 pt-4 text-left>
            <span class="title2">Extra Adult Rates /Without Matters</span>
            <v-text-field
              dense
              v-model="extraAdultWithoutmatter"
              outlined
              type="number"  hide-spin-buttons
              hide-details="auto"
            >
            </v-text-field>
</v-flex>

        </v-layout>
      
      </v-flex>
  <v-flex xs12 sm6>
        <v-layout wrap justify-center fill-height>
          <v-flex xs12 sm10 align-self-start pt-4 text-left>
            <span class="title2">Booking Type </span>

<v-radio-group
dense
v-model="bookingType"
row
class="mt-0 pt-0"
hide-details="auto"
>
<v-radio color="orange"  label="Group Booking" value="Group Package"></v-radio>
<v-radio color="orange"  label="Single Booking" value="Single Room"></v-radio>
</v-radio-group>
          </v-flex>
          <v-flex xs12 sm10 align-self-start pt-4 text-left>
            <span class="title2">Rate Type </span>

<v-radio-group
dense
v-model="rateType"
row
class="mt-0 pt-0"
hide-details="auto"
>
<v-radio color="orange"  label="B2B" value="B2B"></v-radio>
<v-radio color="orange"  label="B2C" value="B2C"></v-radio>
</v-radio-group>
          </v-flex>
      
      <v-flex xs12 sm10 align-self-start pt-4 text-left>
            <span class="title2">GST</span>
            <v-radio-group
          dense
          v-model="GSTType"
          row
          class="mt-0 pt-0"
          hide-details="auto"
        >
          <v-radio color="orange"  label="Including Gst" value="Inclusive"></v-radio>
          <v-radio color="orange"  label="Excluding GST" value="Exclusive"></v-radio>
        </v-radio-group>
          </v-flex>
          <v-flex xs12 sm10 align-self-start pt-4 pt-md-4 text-left>
            <span class="title2">Food </span>

            <v-radio-group
          dense
          v-model="isFood"
          row 
          class="mt-0 pt-0"
          hide-details="auto"
        >
        <v-radio color="orange" label="Include Breakfast" :value="true"></v-radio>
        <v-radio color="orange" label="Room Only" :value="false"></v-radio>
        </v-radio-group>
           
          </v-flex>
          <v-flex xs12 sm10 align-self-start  text-left>
  <v-layout wrap justify-start>

 
<v-flex xs12 sm6 align-self-center pr-0 pr-md-1 pt-4 text-left>

            <v-switch
              v-model="isDrink"
              label="Welcome Drink"
               hide-details
              color="orange" class="mt-0"
            ></v-switch>
</v-flex>
<v-flex xs12 sm6 align-self-center pl-0 pl-md-1 pt-4 text-left v-if="isDrink==true">
        <v-radio-group
          dense
          v-model="drinkType"
          row 
          class="mt-0 pt-0"
          hide-details="auto"
        >
          <v-radio color="orange" label="Free" value="false"></v-radio>
          <v-radio color="orange" label="Paid" value="true"></v-radio>
        </v-radio-group>
</v-flex>
</v-layout>
</v-flex>
          <v-flex xs12 sm10 align-self-start pt-4 text-left  v-if="bookingType=='Group Package'">
            <span class="title2">Min.Rooms</span>

<v-text-field 
  dense
  v-model="minRoom"
  outlined
  type="number"  hide-spin-buttons
  hide-details="auto"
>
</v-text-field>
</v-flex>
          <!-- <v-flex xs12 sm5 pr-0 pr-md-1 pt-4 text-left v-if="bookingType=='single'">
            <span class="title2">Max.Capacity</span>
            <v-text-field
              dense
              v-model="maxCapacity"
              outlined
              type="number"  hide-spin-buttons
              hide-details="auto"
            >
            </v-text-field>
</v-flex>
<v-flex xs12 sm5 pl-0 pl-md-1 pt-4 text-left v-if="bookingType=='single'">
            <span class="title2">Extra.Person</span>
            <v-text-field
              dense
              v-model="extraPerson"
              outlined
              type="number"  hide-spin-buttons
              hide-details="auto"
            >
            </v-text-field>
</v-flex> -->
<v-flex xs12 sm10 pt-4 text-left>
            <span class="title2">Infant / Complimentary Free</span>
            <v-text-field
              dense
              v-model="roomData.infantAge"
              outlined disabled
              hide-details="auto"
            >
            </v-text-field>
</v-flex>
<v-flex xs12 sm10 align-self-start pt-4 text-left>
  <span class="title2">Additional Rules</span>
            <v-textarea
              dense
              v-model="additionalRules"
              outlined
              auto-grow
          rows="4"
              type="text"
              hide-details="auto"
            >
            </v-textarea>
</v-flex>
<v-flex xs12 sm10 pt-4 text-left align-self-baseline>
  <v-layout wrap justify-end>
    <v-flex xs12 sm6>

 
  <v-btn
                  @click="AddRoomRate()"
                  block
                  class="btnstly"
                  style="cursor: pointer"
                  ><span style="font-family: LexendFont; text-transform: none"
                    >Save</span
                  ></v-btn
                >
              </v-flex>
            </v-layout>
</v-flex>
        
        </v-layout>
      </v-flex>
   
    </v-layout>
  </div>
</template>
  <script>
import axios from "axios";
export default {
  props: [ "roomData"],
  data() {
    return {
      showSnackBar: false,
      timeout: 2000,
      ServerError: false,
      appLoading: false,
      msg: null,
      page: 1,
      limit: 20,
      RoomId:localStorage.getItem("RoomId"),
      startDate: new Date().toISOString().slice(0, 10),
      endDate: new Date().toISOString().slice(0, 10),
      nowDate: new Date().toISOString().slice(0, 10),
      menu: false,
      menu2: false,
      baseRate:"",
      bookingType:"Single Room",
      rateType:"b2b",
      GSTType: "",
      isFood:"",
      isDrink:true,
      drinkType:true,
      minRoom:"",
      ageRange:"0-5",
      additionalRules:"",
      paidChildWithmatter:"",
      paidChildWithoutmatter:"",
      extraAdultWithmatter:"",
      extraAdultWithoutmatter:"",
      rules: {
        required: (value) => !!value || "Required.",
        counter: (value) => value.length <= 20 || "Max 20 characters",

        email: (value) => {
          const pattern =
            /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
          return !value || pattern.test(value) || "Invalid e-mail.";
        },
      },
      pinRules: [
        (v) => !!v || "PIN is required",
        (v) => (v && v.length === 6) || "PIN must be 6 digits",
      ],
      phoneRules: [
        (v) => !v || v.length <= 10 || "number must be less than 11 characters",
        (v) =>
          !v || v.length >= 10 || "number must be greater than 10 characters",
        (v) => !v || /^\d{10}$/.test(v) || "phone number must be a number",
      ],
    };
  },
//   mounted() {
 
//  this.getData();
//   },
computed: {
    netRate() {
      if (this.baseRate) {
        return (this.baseRate * (100 / 110)).toFixed(2);
      }
      return null;
    },
  },
  methods: {
    increment(field) {
      this.occupency[field]++;
    },
    decrement(field) {
      if (this.occupency[field] > 0) {
        this.occupency[field]--;
      }
    },
    addNewNo() {
      if (this.newRoomNumber && this.newFloor) {
        this.roomNumbers.push({
          roomNumber: this.newRoomNumber,
          floorNumber: this.newFloor,
        });
        this.newRoomNumber = "";
        this.newFloor = "";
      }
    },
    deleteRoomNo(index) {
      this.roomNumbers.splice(index, 1);
    },

    validation() {
      if (!this.category) {
        this.msg = "Please choose boat category";
        this.showSnackBar = true;
        return;
      } else if (!this.roomName) {
        this.msg = "Please enter property boat name";
        this.showSnackBar = true;
        return;
      } else if (!this.description) {
        this.msg = "Please enter total rooms";
        this.showSnackBar = true;
        return;
      } else if (!this.roomType) {
        this.msg = "Please choose type";
        this.showSnackBar = true;
        return;
      } else if (!this.roomView) {
        this.msg = "Please choose room view";
        this.showSnackBar = true;
        return;
      } else if (!this.otpMail) {
        this.msg = "Please enter OTP mail Id";
        this.showSnackBar = true;
        return;
      } else if (!this.expiryType) {
        this.msg = "Please choose expiry type";
        this.showSnackBar = true;
        return;
      } else if (!this.expiryDate) {
        this.msg = "Please enter expiry date";
        this.showSnackBar = true;
        return;
      } else if (!this.attachments) {
        this.msg = "Please upload boat licence proof";
        this.showSnackBar = true;
        return;
      } else if (!this.attachments2) {
        this.msg = "Please upload lease proof";
        this.showSnackBar = true;
        return;
      } else {
        this.addBoat();
      }
    },
    AddRoomRate() {
      this.appLoading = true;

      axios({
        method: "POST",
        url: "/room/package/rate/add",
        data: {
          startDate: this.startDate,
          endDate: this.endDate,
          basePrice: this.baseRate,
          paidChildRatewithExtraMatress: this.paidChildWithmatter,
          paidChildRatewithoutExtraMatress: this.paidChildWithoutmatter,
          extraAdultRateWithExtraMatress: this.extraAdultWithmatter,
          extraAdultRateWithoutExtraMatress: this.extraAdultWithoutmatter,
          haveWelcomeDrink: this.isDrink,
          additionalRules: this.additionalRules,
          breakfastIncluded: this.isFood,
          rateType: this.bookingType,
          packageType: this.rateType,
          welcomeDrinkPaid: this.drinkType,
          minRooms: this.minRoom,
          gstCalculations: this.GSTType,
          roomId: localStorage.getItem("RoomId"),
        },
        headers: {
          token: localStorage.getItem("token"),
        },
      }).then((response) => {
        this.appLoading = false;
        if (response.data.status == true) {
          this.msg = response.data.msg;
          this.showSnackBar = true;
          this.$emit('id-received', { hotelId: this.$route.query.id });  
        //  localStorage.removeItem("RoomId");
        } else {
          this.msg = response.data.msg;
          this.showSnackBar = true;
        }
      });
      // }
    },
  
  },
};
</script>
<style scoped>
.btnstly {
  background: linear-gradient(
    to bottom,
    rgba(255, 133, 86, 1),
    rgba(247, 75, 9, 1),
    rgba(255, 70, 0, 1)
  );
  color: white;
}
</style>